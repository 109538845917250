import {version} from './version';


export const environment = {
  production: false,
  urls: {
    'gateway': '/gateway/',
    'booking': 'https://buchung.uci-kinowelt.de/',
    'auth': 'auth/oauth/token',
    'passwordReset': 'auth/users/custom/passwordReset',
    'sso' : 'sso',
    'printer': 'ws://127.0.0.1:3360'
  },
  services: {
    'Admin': 'admin/',
    'Area': 'area/',
    'Auditorium': 'auditorium/',
    'Auth': 'auth/',
    'Booking-Session': 'booking-session/',
    'Booking-Transaction': 'booking-transaction/',
    'Booking-Transaction-Additional': 'booking-transaction-additional/',
    // 'Cineplex-Management': 'cineplex-management/',
    // 'Compeso-CineOrder': 'compeso-cineorder/',
    // 'Compeso-Customer': 'compeso-customer/',
    'Compeso-TicketServer': 'compeso-ticketserver/',
    'Documentation': 'documentation/',
    'Event': 'event/',
    'Location': 'location/',
    'Mail': 'mail/',
    'Mandator': 'mandator/',
    'Occupation': 'occupation/',
    'Payment': 'payment/',
    'Payment-Crefopay': 'payment-crefopay/',
    // 'Payment-Payone': 'payment-payone/',
    // 'Payment-Saferpay': 'payment-saferpay/',
    'Pdf': 'pdf/',
    'Performance': 'performance/',
    'Price': 'price/',
    // 'Product': 'product/',
    // 'Sepa': 'sepa/',
    'UCI-Management': 'uci-management/',
  },
  oauth: {
    clientId: '2cefb7cf-2108-49e0-b1ae-fa9d9ed1922b',
    clientIdForAdmin: '00fc7a47-b0ac-4529-bf0b-37ea867eb80a',
    grant_type_login: 'password',
    grant_type_refresh: 'refresh_token'
  },
  s3: {
    authUrl: 'auth/s3auth/file-upload-signature',
    deleteUrl: 'auth/s3auth/deleteObject/public/',
    types: [
      'POSTER',
      'DESIGN'
    ],
    formParam: {
      acl: 'public-read',
      x_amz_server_side_encryption: 'AES256',
      x_amz_algorithm: 'AWS4-HMAC-SHA256',
      x_amz_meta_tag: ''
    },
    allowedMimeType: [
      'image/jpeg',
      'image/png'
    ]
  },
  cdnImage: {
    host : '',
    imageUrl: (url) => (url.startsWith("/assets/img/") || url.startsWith("http://") || url.startsWith("https://")) ? `${url}` : `${environment.cdnImage.host}/${url}`
  },
  youtube: {
    videoUrl: (id) => `https://www.youtube.com/watch?v=${id}`,
    previewImageUrl: (id) => `https://img.youtube.com/vi/${id}/hqdefault.jpg`
  },
  languages: ['de', 'en'],
  defaultLanguage: 'de',
  startOfWeek: 4,
  performanceAllowToDelete: [
    'CREATED',
    'BLOCKED'
  ],
  performanceHours: [
    '11:00',
    '15:00',
    '15:30',
    '16:00',
    '18:00',
    '18:30',
    '20:00',
    '20:30',
    '21:00',
  ],
  performanceMaxNextWeeks: 12,
  userPermissionOrder: [
    'ROLE_ADMIN',
    'ROLE_MANDATOR',
    'ROLE_LOCATION',
    'ROLE_SALES_COUNTER',
    'ROLE_PRE_SALES_COUNTER',
    'ROLE_TICKET_VALIDATOR',
    'ROLE_CUSTOMER',
  ],
  froalaEditor: {
    'key': 'aG3C2A6B10bA4B3E3C1I3H2B6C6C3C2uaymcefA-13D-13F-11D1pp=='
  },
  version: version,
  authorization: {
    adminboard: {
      minRole: 'ROLE_ADMIN'
    },
    dashboard: {
      minRole: 'ROLE_LOCATION'
    },
    events: {
      minRole: 'ROLE_LOCATION'
    },
    performances: {
      minRole: 'ROLE_LOCATION'
    },
    transactions: {
      minRole: 'ROLE_LOCATION'
    },
    locations: {
      minRole: 'ROLE_LOCATION'
    },
    counter: {
      minRole: 'ROLE_PRE_SALES_COUNTER'
    },
    users: {
      minRole: 'ROLE_SALES_COUNTER'
    }
  }
};
